import { render, staticRenderFns } from "./BookingSummaryPage.vue?vue&type=template&id=af4b1a58&scoped=true&"
import script from "./BookingSummaryPage.vue?vue&type=script&lang=js&"
export * from "./BookingSummaryPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af4b1a58",
  null
  
)

export default component.exports