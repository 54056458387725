<template>
  <div>
    <div class="d-flex charge-week">
      <div class="bg-gray header-col">
        <div class="cell">日付</div>
        <div class="d-flex">
          <div class="head-1 cell">宿泊人数</div>
          <div class="flex-grow-1">
            <div class="cell">大人</div>
            <div class="cell">子供（添寝）</div>
            <div class="cell">子供（非添寝）</div>
          </div>
        </div>
        <div class="cell">駐車場予約</div>
        <div class="cell">貸出品予約</div>
        <div class="d-flex" v-for="(item, ind) in subjectsWithPaymentMethod" :key="ind">
          <div class="head-1 cell">{{ item.type }} {{ item.subject }}</div>
          <div class="cell flex-grow-1 text-center bg-reset font-weight-bold">{{ item.paymentMethod }}</div>
        </div>
      </div>
      <ChargeDate v-for="date in weekDates" :key="date" class="detail-day" :stayDate="date" :summary="summary" />
    </div>
  </div>
</template>

<script>
import bookingSummaryMixin from './BookingSummary.mixin'
import ChargeDate from './ChargeDate.vue'

export default {
  mixins: [bookingSummaryMixin],
  props: {
    pageNo: Number,
    maxPageNo: Number,
    summary: Object,
    weekDates: Array
  },
  components: { ChargeDate }
}
</script>

<style lang="scss" scoped>
.header-col {
  width: 80mm;
}
.head-1 {
  width: 53mm;
}
.charge-week {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 1px 0 0 1px;
}
::v-deep .cell {
  border: 1px solid #000;
  margin: -1px 0 0 -1px;
  overflow: hidden;
}
</style>
