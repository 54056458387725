<template>
  <div>
    <div>{{ title }}</div>
    <div class="area">
      <div v-for="(cell, index) in cells" :key="index" class="item">
        <div class="bg-gray label">
          {{ cell.label }}
        </div>
        <div class="value">
          {{ cell.value || '-'}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    cells: Array
  }
}
</script>

<style lang="scss" scoped>
.area {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 1px 0 0 1px;
}
.item {
  width: 50%;
  max-width: 50%;
  display: flex;
  & .label {
    width: 30mm;
    text-align: center;
  }
  & .value {
    flex-grow: 1;
    text-align: center;
  }
  & > div {
    border: 1px solid #000;
    margin: -1px 0 0 -1px;
  }
}
</style>
