<template>
  <div class="v-application" style="display: block;">
    <h3 class="text-center">{{ facility.name }} 予約票 <span class="page-number">&nbsp;</span></h3>
    <div class="text-right text-small">最終更新日時: {{ lastUpdatedAt | dateTimeStingWithSec }}</div>
    <div class="data-area">
      <div class="col1">
        <div>予約ID：<span class="ml-4">{{ bookingName }}</span></div>
        <div class="check-in-out mb-2">
          <div>
            <div>C/I</div>
            <div>{{  booking.checkInDate | jaLongDateNoDow }}</div>
          </div>
          <div>～</div>
          <div>
            <div>C/O</div>
            <div>{{  booking.checkOutDate | jaLongDateNoDow }}</div>
          </div>
          <div>({{ stayDates.length }}泊{{ stayDates.length + 1 }}日)</div>
        </div>

        <div class="room-info">
          <div>部屋タイプ：</div>
          <div>{{  roomType.name }}</div>
        </div>
        <div class="room-info">
          <div>部屋番号：</div>
          <div>{{  room.name }}</div>
        </div>
      </div>
      <div class="col2">
        <div style="width: 160px">
          <div class="pax-num"><div>大人：</div><div>{{ adultCount }}名</div></div>
          <div class="pax-num"><div>子供（添い寝）：</div><div>{{ bedshareCount }}名</div></div>
          <div class="pax-num"><div>子供（非添寝）：</div><div>{{ childCount }}名</div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bookingSummaryMixin from './BookingSummary.mixin'

export default {
  mixins: [bookingSummaryMixin],
  props: {
    pageNo: Number,
    maxPageNo: Number,
    summary: Object
  }
}
</script>

<style lang="scss" scoped>
.data-area {
  font-weight: bold;
  display: flex;
  align-items: center;
}
.col1 {
  flex-grow: 7;
}
.col2 {
  flex-grow: 3;
}
.pax-num {
  display: flex;
  justify-content: space-between;
}
.check-in-out {
  padding-left: 10mm;
  padding-right: 30mm;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.room-info {
  display: flex;
  // first child should have fixed width
  & > div:first-child {
    width: 45mm;
  }
}
</style>
