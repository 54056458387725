import { getStayDateRangeArray } from '@/utils/dateUtil'
import { BookingTypeIds } from '@/constants/bookingType'
import { ProductTypeNameToId } from '@/constants/productTypeId'

export default {
  computed: {
    booking () { return this.summary?.booking },
    changeHistories () { return this.summary?.chageHistories },
    bookingName () {
      if (!this.booking) return '予約概要'
      return `${this.booking.bookingType?.code}予約_予約ID_${this.booking.id}`
    },
    facility () { return this.booking?.room?.roomType?.facility },
    room () { return this.booking?.room },
    roomType () { return this.booking?.room?.roomType },
    stayDates () {
      return getStayDateRangeArray(this.booking.checkInDate, this.booking.checkOutDate)
    },
    lastUpdatedAt () {
      const dates = [...this.changeHistories.map(h => h.changedAt, this.booking.createdAt)]
      return new Date(dates.sort((a, b) => new Date(b) - new Date(a))[0])
    },
    bookingStatus () {
      if (this.booking.cancelledAt) return 'キャンセル'
      // if the last upddated at is more than 1 minutes after createdAt, this booking's status is modified
      if (new Date(this.lastUpdatedAt) - new Date(this.booking.createdAt) > 60000) return '予約変更'
      return '新規予約'
    },
    client () {
      return this.booking.client
    },
    clientId () {
      return this.client?.id
    },
    representative () {
      return this.booking.representative
    },
    productTypeId () {
      return this.booking.contract?.productTypeId
    },
    bookingTypeName () {
      let typeIdForName = this.booking.bookingTypeId
      if (typeIdForName === BookingTypeIds.POINT) {
        switch (this.productTypeId) {
          case ProductTypeNameToId.SP: typeIdForName = BookingTypeIds.SP; break
          case ProductTypeNameToId.FP: typeIdForName = BookingTypeIds.FP; break
          case ProductTypeNameToId.VS: typeIdForName = BookingTypeIds.VS; break
          case ProductTypeNameToId.VM: typeIdForName = BookingTypeIds.MO; break
          default: typeIdForName = BookingTypeIds.POINT; break
        }
      }
      switch (this.booking.bookingTypeId) {
        case BookingTypeIds.MW: return 'マイウィーク（MW）'
        case BookingTypeIds.DP: return 'デイプラン（DP）'
        case BookingTypeIds.SP: return 'シェアリングポイント（SP）'
        case BookingTypeIds.FP: return 'フレックスポイント（FP）'
        case BookingTypeIds.PW: return 'プライムウィーク（PW）'
        case BookingTypeIds.VM: return 'バケーションマスターズ（VM）'
        case BookingTypeIds.MO: return 'マスターズオプション（MO）'
        case BookingTypeIds.VS: return 'バケーションスタイル（VS）'
        case BookingTypeIds.OTA: return 'OTA'
        case BookingTypeIds.TICKET: return this.clientId ? '法人チケット' : '優待券'
        case BookingTypeIds.TRIAL: return '体験'
        case BookingTypeIds.OTHER: return 'その他'
        case BookingTypeIds.POINT: return 'ポイント'
        default: return 'その他（未定義）'
      }
    },
    numberOfPets () {
      if (this.booking.petInfo) {
        return this.booking.petInfo.match(/^ペット(?:\d+)：$/gm)?.length ?? 0
      }
      return 0
    },
    chargeStayDates () {
      const stayDates = [...this.stayDates]
      // there could be charges outsitde of stay dates (e.g. cancellation fee)
      // so we need to add those dates to stayDates
      this.booking.charges.forEach(charge => {
        const chargeDate = charge.stayDate
        if (!stayDates.includes(chargeDate)) stayDates.push(chargeDate)
      })
      return stayDates.sort((a, b) => new Date(a) - new Date(b))
    },
    chargeTotal () {
      return this.booking.charges.reduce((acc, charge) => acc + charge.totalPrice ?? 0, 0)
    },
    allChargesUniqueSubject () {
      const result = []
      const subjects = new Set()
      this.booking.charges.forEach(charge => {
        if (subjects.has(charge.subject)) return
        subjects.add(charge.subject)
        result.push({
          type: charge.type.name,
          subject: charge.subject
        })
      })
      return result
    },
    otherBookingIds () {
      return this.summary.otherBookingIds.filter(id => id !== this.booking.id)
    },
    allChargeSubjects () {
      return this.allChargesUniqueSubject.map(subject => subject.subject)
    },
    detailByStayDate () {
      const detailByDate = {}
      this.chargeStayDates.forEach(stayDate => {
        detailByDate[stayDate] = this.getDetailByStayDate(stayDate)
      })

      return detailByDate
    },
    guestIdToType () {
      const guestIdToType = {}
      this.booking.guests.forEach(guest => {
        guestIdToType[guest.id] = guest.child ? (guest.bedShared ? 'bedshare' : 'child') : 'adult'
      })
      return guestIdToType
    },
    adultCount () {
      return this.booking.guests.filter(guest => this.guestIdToType[guest.id] === 'adult').length
    },
    childCount () {
      return this.booking.guests.filter(guest => this.guestIdToType[guest.id] === 'child').length
    },
    bedshareCount () {
      return this.booking.guests.filter(guest => this.guestIdToType[guest.id] === 'bedshare').length
    },
    subjectsWithPaymentMethod () {
      return this.allChargesUniqueSubject.map(charge => {
        return {
          ...charge,
          paymentMethod: this.subjectToPaymentMethod(charge.subject)
        }
      })
    },
    stayDateWeeks () {
      // split chargeStayDates into weeks
      const weeks = []
      let week = []
      this.chargeStayDates.forEach(stayDate => {
        if (week.length === 7) {
          weeks.push(week)
          week = []
        }
        week.push(stayDate)
      })
      if (week.length) weeks.push(week)

      return weeks
    }
  },
  methods: {
    getDetailByStayDate (stayDate) {
      const charges = this.booking.charges.filter(charge => charge.stayDate === stayDate)
      const guestSchedules = this.booking.guestSchedules.filter(schedule => schedule.stayDate === stayDate && schedule.stay)
      const adultCount = guestSchedules.filter(schedule => this.guestIdToType[schedule.guestId] === 'adult').length
      const childCount = guestSchedules.filter(schedule => this.guestIdToType[schedule.guestId] === 'child').length
      const bedshareCount = guestSchedules.filter(schedule => this.guestIdToType[schedule.guestId] === 'bedshare').length
      const parkingCount = this.booking.parkings.filter(parking => parking.fromDate <= stayDate && parking.toDate > stayDate).length
      const rentalItemCount = this.booking.rentalItems.filter(item => item.fromDate <= stayDate && item.toDate > stayDate).length
      const chargeAmounts = this.allChargeSubjects.map(subject => {
        // get the sum of the charges for the subject
        const jpyAmount = charges.filter(charge => charge.subject === subject).reduce((acc, charge) => acc + charge.totalPrice ?? 0, 0)
        return jpyAmount
      })

      return {
        stayDate,
        adultCount,
        childCount,
        bedshareCount,
        parkingCount,
        rentalItemCount,
        chargeAmounts
      }
    },

    subjectToPaymentMethod (subject) {
      const bg = this.summary.bookingGroup
      if (bg.registerdCCSubjects.includes(subject)) {
        return '登録カード'
      }
      if (bg.onsiteSubjects.includes(subject)) {
        return '現地精算'
      }
      if (bg.defaultPaymentType === 'RegisteredCC') {
        return '登録カード'
      } else {
        return '現地精算'
      }
    }
  }
}
