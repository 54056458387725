<template>
  <BookingSummary :bookingId="bookingId" updateTitle />
</template>

<script>
import BookingSummary from '@/components/Booking/BookingSummary'

export default {
  components: { BookingSummary },
  computed: {
    bookingId () {
      return parseInt(this.$route.query.id, 10)
    }
  },
  mounted () {
    if (!this.bookingId) {
      this.$router.push({ name: 'NotPage' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
