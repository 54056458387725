<template>
  <div>
    <div class="font-weight-bold">処理登録・変更履歴（最新5件のみ記載）</div>
    <div>
      <table>
        <thead>
          <tr>
            <th>変更受付日時</th>
            <th>ユーザー</th>
            <th>設定</th>
            <th>変更前</th>
            <th>変更後</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="history in changeHistories" :key="history.id" class="history-row">
            <td>{{ history.changedAt | dateTimeSting }}</td>
            <td>{{ history.changedByName }}</td>
            <td>{{ history.fieldName }}</td>
            <td>{{ history.valueBefore }}</td>
            <td>{{ history.valueAfter }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import bookingSummaryMixin from './BookingSummary.mixin'

export default {
  mixins: [bookingSummaryMixin],
  props: {
    summary: Object
  }
}
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  width: 100%;
  & td,th {
    border: 1px solid #000;
    width: 20%;
  }

  & td {
    padding-left: 1mm;
  }
}
.history-row {
  & td {
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
