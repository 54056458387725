<template>
  <div class="detail-day">
    <div class="cell">{{ stayDate | jaShortDateWithDow }}</div>
    <div class="cell">{{ detail.adultCount }}</div>
    <div class="cell">{{ detail.bedshareCount }}</div>
    <div class="cell">{{ detail.childCount }}</div>
    <div class="bg-gray cell">{{ detail.parkingCount }}</div>
    <div class="bg-gray cell">{{ detail.rentalItemCount ? 'あり' : '-' }}</div>
    <div v-for="(amount, ind) in detail.chargeAmounts" :key="ind" class="cell font-weight-bold">
      <template v-if="amount">{{ amount | currency2 }}</template>
      <template v-else>-</template>
    </div>
  </div>
</template>

<script>
import bookingSummaryMixin from './BookingSummary.mixin'

export default {
  mixins: [bookingSummaryMixin],
  props: {
    stayDate: String,
    summary: Object
  },
  computed: {
    detail () {
      return this.detailByStayDate[this.stayDate]
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-day {
  width: 25mm;
}
.cell {
  text-align: center;
}
</style>
