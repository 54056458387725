<template>
  <div v-if="summary" class="print-a4">
    <div id="page-1" class="page">
      <Header :summary="summary" :pageNo="1" :maxPageNo="2" id="header" />
      <div class="text-size-normal">
        <InfoBlock class="mt-2" title="" :cells="infoBlock1" />
        <InfoBlock class="mt-2" title="会員情報" :cells="infoBlock2" />
        <InfoBlock class="mt-2" title="宿泊代表者" :cells="infoBlock3" />

        <div class="font-weight-bold my-4">グループ予約ID: {{ otherBookingIds.join(', ') }}</div>
        <div class="font-weight-bold mb-4 d-flex">
          ペット頭数：{{ numberOfPets }}頭
          <div class="ml-8">
            {{ booking.hasAgreedPetPolicy ? '愛犬規約同意済み' : '愛犬規約未同意' }}
          </div>
        </div>
        <div class="mb-4">
          <div class="font-weight-bold">備考</div>
          <div class="remarks">
            <textarea ref="textarea" style="height: 100%; width: 100%; overflow: hidden; resize: none;" />
            <FormatSpan v-if="false" :value="booking.remarks" />
          </div>
        </div>
        <ChangeHistory :summary="summary" />
      </div>
    </div>

    <div id="page-2" class="page">
      <div class="mb-4 mt-8 text-size-normal" style="width: 70mm">
        <div class="d-flex justify-space-between">
          <div>合計金額</div>
          <div>{{ chargeTotal | currency2 }}</div>
        </div>
        <div class="d-flex justify-space-between">
          <div>TVP利用</div>
          <div>{{ summary.prepaymentAmount | toThousands }}Pt</div>
        </div>
        <div class="d-flex justify-space-between" style="border-top: 1px solid black;">
          <div>現地精算金額</div>
          <div>{{ chargeTotal - summary.prepaymentAmount | currency2 }}</div>
        </div>
      </div>

      <div class="charge-weeks text-size-normal">
        <div>宿泊詳細</div>
        <ChargeWeek v-for="(week, weekInd) in stayDateWeeks" :key="weekInd" :summary="summary" :weekDates="week" class="charge-week" />
        <div style="position: absolute; right: 0;">以上</div>
      </div>
    </div>
  </div>
</template>

<script>
import { BOOKING_SUMMARY } from '@/api/graphql/bookingDetail/booking-detail-basic'
import Header from './Header.vue'
import ChangeHistory from './ChangeHistory.vue'
import InfoBlock from './InfoBlock.vue'
import { dateTimeSting } from '@/utils/filters'
import bookingSummaryMixin from './BookingSummary.mixin'
import FormatSpan from '@/components/shared/FormatSpan.vue'
import ChargeWeek from './ChargeWeek.vue'

export default {
  mixins: [bookingSummaryMixin],
  components: { Header, InfoBlock, FormatSpan, ChangeHistory, ChargeWeek },
  props: {
    bookingId: {
      type: Number,
      required: true
    },
    updateTitle: Boolean
  },
  data () {
    return {
      summary: null
    }
  },
  computed: {
    infoBlock1 () {
      return [
        { label: '更新ステータス', value: this.bookingStatus },
        { label: '予約日時', value: dateTimeSting(this.booking.createdAt) },
        { label: '宿泊契約', value: this.bookingTypeName }
      ]
    },
    infoBlock2 () {
      return [
        { label: '会員番号', value: this.client?.memberId },
        { label: '顧客番号', value: this.client?.id },
        { label: '会員名', value: this.client?.name },
        { label: 'フリガナ', value: this.client?.nameKana }
      ]
    },
    infoBlock3 () {
      return [
        { label: '宿泊代表者名', value: this.representative?.name },
        { label: 'フリガナ', value: this.representative?.kana },
        { label: '連絡番号', value: this.representative?.tel }
      ]
    }

  },
  watch: {
    bookingName () {
      if (this.updateTitle) {
        document.title = this.bookingName
      }
    }
  },
  async mounted () {
    const result = await this.$gqlLoading({
      query: BOOKING_SUMMARY,
      variables: { id: this.bookingId }
    })
    this.summary = result.data.bookingSummary
    this.$nextTick(async () => {
      this.$refs.textarea.innerHTML = this.booking.remarks
      const result = await window.PagedPolyfill.preview()
      if (result?.rendered) {
        window.print()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.print-a4 {
  size: A4 portrait;
  margin: 0mm;
  padding: 0mm;
}
.page {
  page-break-inside: avoid;
}

.charge-week {
  page-break-inside: avoid;
  margin-bottom: 7mm;
  padding-top: 2px;
}

.remarks {
  border: 2px solid #000;
  height: 110mm;
  overflow: hidden;
}
@page
{
    /* this affects the margin in the printer settings */
    margin: 10mm 10mm 10mm 10mm;
    padding-top: 35mm;
}

#header {
  position: fixed;
  top: 0;
  left: 10mm;
  width: 195mm;
}
</style>

<style lang="scss">
.stg-alert {
  display: none !important;
}
.bg-gray {
  background-color: #f2f2f2;
}
.bg-reset {
  background-color: white;
}

@media print {
  .page-number::after {
    content: "(" counter(page) '/' counter(pages) ")";
  }
}
</style>
